﻿import axios from 'axios';

var isDevice = false;
var searchActive = false;
var filtersActive = false;

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
  isDevice = true;
}


(function () {
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload();
    }
  };
})();

setTimeout(function () {
  var allForms = $('.inputs input');
  for (var i = 0; i < allForms.length; i++) {
    if ($(allForms[i]).val().length > 0) {
      $(allForms[i]).addClass('has-content');
    }
  }

}, 600);

window.scrollToTop = function (e) {
  //e.preventDefault();
  setTimeout(function () {
    var elmnt = document.getElementById("header-top").offsetTop;
    window.scrollTo({ top: elmnt - 50, behavior: 'smooth' });
  }, 50);
}

setTimeout(function () {
  document.querySelectorAll('a').forEach(el => el.addEventListener('click', siteTransition));

  $('.register-button').click(function () {
    $('.custom-loader').addClass('show');
  });

}, 500);

setTimeout(function () {
  document.querySelectorAll('.arrow-scroll-hor').forEach(el => el.addEventListener('click', scrollHorSome));

}, 600);


window.scrollHorSome = function (e) {
  let current = e.target;
  let nextSibling = current.nextElementSibling;
  //console.log(nextSibling);

  var whatToScroll = current.nextElementSibling;

  setTimeout(function () {

    whatToScroll.scrollTo({ left: 500, behavior: 'smooth' });
  }, 50);
}

document.querySelectorAll('.overview').forEach(el => el.addEventListener('scroll', combinationScroll));

export function combinationScroll() {
  var st = $(this).scrollTop();

  if (st < 10) {
    $(this).removeClass("scrolled");
  } else {
    $(this).addClass("scrolled");
  }
}


// Show custom element on scroll down
var didScroll;

var navbarHeight = $('.header').outerHeight() + 100;
var supportPageOffset = window.pageXOffset !== undefined;
var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

$(window).on('scroll', function (event) {
  didScroll = true;
});

setInterval(function () {
  if (didScroll) {
    hasScrolled();
    didScroll = false;
  }
}, 250);


window.hasScrolled = function () {

  var st = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

  if (st > navbarHeight) {
    $('.header').addClass('header-scrolled');

  } else {
    $('.header').removeClass('header-scrolled');
  }

}

window.siteTransition = function (e) {

  if (!e.target.classList.contains('scroll-to-btn') &&
    !e.target.classList.contains('external') &&
    !e.target.classList.contains('attachmentLink') &&
    !e.target.classList.contains('dont-navigate') &&
    !e.target.classList.contains('level1-desktop') &&
    !e.target.classList.contains('print-order-button') &&
    (e.target.getAttribute("href") != null && e.target.getAttribute("href").indexOf('#') < 0) &&
    (e.target.getAttribute("href") != null && e.target.getAttribute("href").indexOf('http') < 0) &&
    (e.target.getAttribute("href") != null && e.target.getAttribute("href").indexOf('mailto') < 0)) {

    if (
      e.ctrlKey ||
      e.shiftKey ||
      e.metaKey || // apple
      (e.button && e.button == 1) // middle click, >IE9 + everyone else
    ) {
      //console.log("NEW TAB")
    } else {
      //console.log("DEFAULT")
      $('.custom-loader').addClass('show');
    }
  }
}

var customerServiceActive = false;

window.openCustomerService = function () {
  if (!customerServiceActive) {
    $('.customer-service-overlay').addClass('active');
    $('#customer-service').addClass('active');
    customerServiceActive = true;
  } else {
    $('.customer-service-overlay').removeClass('active');
    $('#customer-service').removeClass('active');
    customerServiceActive = false;
  }

}

window.CloseCustomerService = function () {
  $('#customer-service').removeClass('active');
  $('.customer-service-overlay').removeClass('active');
}

window.CloseOpenSections = function () {
  CloseCustomerService();
};

if (isDevice) {

  $('.item-box').bind('touchstart touchend', function (e) {
    $(this).toggleClass('mobile-hover');
  });

}

window.toggleNav = function () {
  var mainBody = document.getElementsByTagName("body")[0];


  if(mainBody.classList.contains('menu-active')) {

    document.querySelectorAll('.top-menu .level0.has-children > a').forEach((f) => {
      f.classList.remove('active');
      f.classList.remove('expanded');
      f.classList.remove('fade-down');
    });

    document.querySelectorAll('.sublist').forEach((f) => {
        f.classList.remove('active');
    });

    mainBody.classList.remove("menu-active");
    mainBody.classList.add("menu-not-active");

  } else {
    mainBody.classList.add("menu-active");
    mainBody.classList.remove("menu-not-active");
  }


};

window.ToggleSearch = function () {

  if (!searchActive) {

    $('.header-search').addClass('show');
    $('.header').addClass('search-active');
    $('.menu-item').removeClass('active');
    $('.custom-overlay').removeClass('active');
    $('.sub-category-navigation').hide();

    document.getElementById('small-searchterms').focus();

    searchActive = true;

  } else {

    $('.header-search').removeClass('show');
    $('.header').removeClass('search-active');

    searchActive = false;

  }
}

window.ToggleMobileFilters = function () {

  if (!filtersActive) {
    //$('.nopAjaxFilters7Spikes').css('display', 'block');
    $('.nopAjaxFilters7Spikes').addClass('show');
    filtersActive = true;

    $('.filtersTitlePanel').click(function () {
      $('.nopAjaxFilters7Spikes').removeClass('show');
      filtersActive = false;
    });

  } else {
    //$('.nopAjaxFilters7Spikes').css('display', 'none');
    $('.nopAjaxFilters7Spikes').removeClass('show');
    filtersActive = false;

  }
}

var pageTextExpanded = false;

if (document.querySelector(".category-description")) {
  var pageBodyHeight = document.querySelector(".category-description").offsetHeight;
  document.querySelector(".category-description").setAttribute('data-height', pageBodyHeight);

  if (pageBodyHeight > 80) {
    document.querySelector(".category-description-btns").classList.add("show");
    document.querySelector(".category-description").classList.add("hide");
  }
}

window.expandBodyText = function () {
  if (!pageTextExpanded) {
    document.querySelector(".category-description-btns a").innerText = "Skjul";
    document.querySelector(".category-description").classList.remove("hide");
    pageTextExpanded = true;

  }
  else {
    document.querySelector(".category-description-btns a").innerText = "Les mer";
    document.querySelector(".category-description").classList.add("hide");
    pageTextExpanded = false;
  }

}


if (document.querySelector(".manufacturer-description")) {
  var pageBodyHeight = document.querySelector(".manufacturer-description").offsetHeight;
  document.querySelector(".manufacturer-description").setAttribute('data-height', pageBodyHeight);

  if (pageBodyHeight > 80) {
    document.querySelector(".category-description-btns").classList.add("show");
    document.querySelector(".manufacturer-description").classList.add("hide");
  }
}

window.expandBodyManuText = function () {
  if (!pageTextExpanded) {
    document.querySelector(".category-description-btns a").innerText = "Skjul";
    document.querySelector(".manufacturer-description").classList.remove("hide");
    pageTextExpanded = true;

  }
  else {
    document.querySelector(".category-description-btns a").innerText = "Les mer";
    document.querySelector(".manufacturer-description").classList.add("hide");
    pageTextExpanded = false;
  }

}

document.querySelectorAll('.item-grid').forEach(el => el.addEventListener('scroll', gridScroll));

function gridScroll() {
  var st = $(this).scrollLeft();

  if (st < 10) {
    $(this).parent().removeClass("scrolled");
  } else {
    $(this).parent().addClass("scrolled");
  }
}




window.showShipInfo = function () {
  $('.shipment-info').toggle();
}

window.showPickupInfo = function () {
  $('.pickup-info').toggle();
}

window.showDeliveryInfo = function () {
  $('.delivery-info').toggle();
}

var addToCartBtnPresent = false;

if (document.querySelector('.product-details-page') !== null) {
  setTimeout(function () {
    if (!addToCartBtnPresent) {
      //addToCartBtnPresent =true
      var addToCartBtn = document.querySelector('.add-to-cart-button');
      //console.log("knapp? " + addToCartBtn);
      document.querySelector('.loader').classList.add('done');

      if (addToCartBtn === null) {
        //console.log("no btn");
        $('.stock .value').text("Ikke tilgjengelig");
        $('.overview .custom-klarna').addClass('hide');
        $('.overview .custom-shopping-info').addClass('hide');

      }
    }
  }, 2000);
}


// PRODUKT - custom stepper

document.querySelectorAll('.btn-qty-add').forEach(el => el.addEventListener('click', qtyAdd));
document.querySelectorAll('.btn-qty-subtract').forEach(el => el.addEventListener('click', qtySubtract));

function qtyAdd() {
  var qtyId = this.getAttribute('data-stock-id');
  if (qtyId) {
    document.querySelector('#stock-' + qtyId).stepUp();
    if (document.querySelector('#stock-' + qtyId).value > 1) {
      document.querySelector('.comb-row-' + qtyId + '.btn-qty-subtract').classList.remove("inactive");
    }
  }
  else {
    document.querySelector('.qty-input').stepUp();
    if (document.querySelector('.qty-input').value > 1) {
      document.querySelector('.btn-qty-subtract').classList.remove("inactive");
    }
  }

}

function qtySubtract() {
  var qtyId = this.getAttribute('data-stock-id');
  if (qtyId) {
    document.querySelector('#stock-' + qtyId).stepDown();
    if (document.querySelector('#stock-' + qtyId).value <= 1) {
      document.querySelector('.comb-row-' + qtyId + '.btn-qty-subtract').classList.add("inactive");
    }
  }
  else {
    document.querySelector('.qty-input').stepDown();

    if (document.querySelector('.qty-input').value <= 1) {
      document.querySelector('.btn-qty-subtract').classList.add("inactive");
    }
  }
}

// HANDLEKURV - custom stepper

document.querySelectorAll('.btn-cart-qty-add').forEach(el => el.addEventListener('click', cartQtyAdd));
document.querySelectorAll('.btn-cart-qty-subtract').forEach(el => el.addEventListener('click', cartQtySubtract));

function cartQtyAdd() {
  document.querySelector('#' + this.dataset.id).stepUp();
  console.log(this.dataset.id)

  if (document.querySelector('#' + this.dataset.id).value > 1) {
    document.querySelector('.btn-cart-qty-subtract').classList.remove("inactive");
  }

  if (document.querySelector('.update-cart-button') != null) {
    $('.custom-loader').addClass('show');
    $('.update-cart-button').click();
  }
}

function cartQtySubtract() {
  document.querySelector('#' + this.dataset.id).stepDown();

  if (document.querySelector('#' + this.dataset.id).value <= 1) {
    document.querySelector('.btn-cart-qty-subtract').classList.add("inactive");
  }

  if (document.querySelector('.update-cart-button') != null) {
    $('.custom-loader').addClass('show');
    $('.update-cart-button').click();
  }
}

window.CalculateFreightCost = function (sku, zipcode, quantity) {
  if (quantity == null || quantity === 0) {
    quantity = 1;
  }

  $(".freight-result").html("Henter priser..");

  axios.post(
    "/Logistra/CalulateFreightCost",
    {
      sku: sku,
      zipcode: zipcode,
      quantity: quantity
    }
  )
    .then(function (response) {
      console.log(response);
      var data = response.data;

      if (data.Errors.length > 0) {
        $(".freight-result").html("Kunne ikke regne ut frakt. Er postnummer korrekt?");
      } else {
        $(".freight-result").html("");
        var options = data.ShippingOptions;
        var items = [];

        $.each(options, function (key, val) {
          items.push("<li id='" + key + "'>" + val.Name + ": " + val.Rate + "kr" + "</li>");
        });

        $("<ul/>", {
          "class": "freight-list",
          html: items.join("")
        }).appendTo(".freight-result");
      }
    }).catch(function (request) {
      $(".freight-result").html("Kunne ikke regne ut pris");
    });
}

window.ShowFreight = function () {
  $('.freight-calculation').toggle();
  $('#zipcode-freight').focus();
}
