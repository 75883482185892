﻿const { reset } = require("./lib/dragscroll");
const { gridScroll } = require("./general");


var filterContainer = document.querySelector(".nopAjaxFilters7Spikes");
if (filterContainer) {
  //console.log(filterContainer.childElementCount);
  if (filterContainer.childElementCount < 1) {
    document.querySelector(".custom-filter-mobile-btn").classList.add("hide");
  }
}

$(document).on('nopProductRibbonsLoadingCompleteEvent', function () {

  var percentage = $('.ribbon-text');
  for (var i = 0; i < percentage.length; i++) {
    var percentageText = $(percentage[i]).html();
    if (percentageText.match(/\%+\%/)) {
      // Remove the extra '%' sign
      $(percentage[i]).html(percentageText.replace(/\%+\%/, '%'));
    }
  }

  setItemBoxClick();

});


$(document).on('nopAjaxCartProductAddedToCartEvent', function () {
  $('#topcartlink .ico-cart').addClass('has-items');
  var text = $('.cart-qty').text().replace(/"/g, "").replace(/'/g, "").replace(/\(|\)/g, "");;
  $('.cart-qty').text(text);

  $('.productAddedToCartWindowCheckout').click(function () {
    $('.custom-loader').addClass('show');
  });

  setTimeout(function () {
    $('.shipping-graph-bar').width($('.shipping-graph-bar').data("size") + '%');
    $('.shipping-graph-bar').addClass('animate');
    reset();
    document.querySelectorAll('.item-grid').forEach(el => el.addEventListener('scroll', gridScroll));
  }, 200);

});

window.enableBuyButn = function () {
  document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.remove('choose-size');
  document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.remove('disabled');
  document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Kjøp";
  $('.custom-qty').removeClass('hide');
  $('.notify-me').removeClass('show');
  $('.overview .custom-klarna').removeClass('hide');
  $('.overview .custom-shopping-info').removeClass('hide');
  $('.overview .freight-btn').removeClass('hide');
  $('#vipps-express-checkout').removeClass('hide');

}

$(document).on('nopAjaxFiltersFiltrationCompleteEvent', function () {
  $(".filtersGroupPanel.filtersCheckboxPanel").hide();
  $(".toggleControl").addClass("closed");

  $('.nopAjaxFilters7Spikes').removeClass('show');

  setItemBoxClick();
  setSrcOnAsyncImage();
});

window.setItemBoxClick = function () {
  document.querySelectorAll('.item-box a').forEach(el => el.addEventListener('click', clickItemBox));

}

window.addEventListener('DOMContentLoaded', (event) => {
  setItemBoxClick();
  setSrcOnAsyncImage();
});

window.clickItemBox = function (e) {
  if (
    e.ctrlKey ||
    e.shiftKey ||
    e.metaKey || // apple
    (e.button && e.button == 1) // middle click, >IE9 + everyone else
  ) {
  } else {
    $('.custom-loader').addClass('show');
  }
}

$(document).on("nopAjaxCartButtonsAddedEvent", function () {
  if (document.querySelector('.product-details-page') !== null) {
    addToCartBtnPresent = true;
    GetAttributesWithoutStock();
  }
});


function GetAttributesWithoutStock() {
  var productId = document.querySelector('.product-essential').parentElement.getAttribute('data-productid');
  var totalCountOfVariants = document.querySelectorAll('.option-list li').length;
  if (totalCountOfVariants > 0) {
    $.ajax({
      method: "GET",
      url: "/Stock/GetAttributesWithoutStock/" + productId,
      success: function (productsWithoutStock) {

        $.each(productsWithoutStock, function (i, o) {
          var productElement = $('input[value="' + o + '"]');
          $(productElement).addClass('out-of-stock');
          $(productElement).siblings('label').addClass('out-of-stock');

          if ($(productElement).siblings('label').html() === "") { // hvis størrelse ikke har navn
            $(productElement).siblings('label').addClass('no-name');
          }

          $(productElement).click(function () {
            document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.add('disabled');
            document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Utsolgt";
            $('.notify-me').addClass('show');
            $('.custom-qty').addClass('hide');
            $('.overview .custom-klarna').addClass('hide');
            $('.overview .custom-shopping-info').addClass('hide');
            $('.overview .freight-btn').addClass('hide');
            $('#vipps-express-checkout').addClass('hide');
          });
        });

        if (productsWithoutStock.length === totalCountOfVariants) {
          document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.add('disabled');
          document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Utsolgt";
          $('.custom-qty').addClass('hide');
          $('.notify-me').addClass('show');
          $('.overview .custom-klarna').addClass('hide');
          $('.overview .custom-shopping-info').addClass('hide');
          $('.overview .freight-btn').addClass('hide');
          $('#vipps-express-checkout').addClass('hide');

          if (totalCountOfVariants === 1) {
            console.log("Bare 1 her?");
            var productElement = $('.attributes .option-list li input');
            productElement.click();
            productElement.prop('checked', true);



            setTimeout(function () {
              var productElement = $('.attributes .option-list li input');
              productElement.prop('checked', true);
            }, 200);

            setTimeout(function () {
              var productElement2 = $('.attributes .option-list li input');
              productElement2.click();
            }, 300);
          }

        } else {

          if (totalCountOfVariants === 1) {
            setTimeout(function () {
              var productElement = $('.attributes .option-list li input');
              productElement.prop('checked', true);
            }, 200);

            setTimeout(function () {
              var productElement2 = $('.attributes .option-list li input');
              productElement2.click();
            }, 300);

            if ($(productElement).siblings('label').html() === "") {
              $(productElement).siblings('label').addClass('no-name');
            }

          } else {
            document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.add('choose-size');
            document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Velg størrelse først";

            var sizeInputs = document.querySelectorAll('.option-list li input:not(.out-of-stock)');
            sizeInputs.forEach(el => el.addEventListener('click', enableBuyButn));

          }

        }

        document.querySelector('.loader').classList.add('done');

      },
      error: function (request, status, error) {
      }
    });
  }
  else {
    $.ajax({
      method: "GET",
      url: "/Stock/GetStockForProduct/" + productId,
      success: function (data) {
        console.log(data);
        if (data.inventoryMethod != 0 && data.backorder != true) {
          var productType = data.type;
          var productStock = data.stock;

          if (productStock < 1 && productType === 'Product') {
            document.querySelector('.nopAjaxCartProductVariantAddToCartButton').classList.add('disabled');
            document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Utsolgt";
            $('.notify-me').addClass('show');
            $('.custom-qty').addClass('hide');
            $('.overview .custom-klarna').addClass('hide');
            $('.overview .custom-shopping-info').addClass('hide');
            $('.overview .freight-btn').addClass('hide');
            $('#vipps-express-checkout').addClass('hide');
          }
        }
        if (data.backorder && data.stock < 1) {
          document.querySelector('.nopAjaxCartProductVariantAddToCartButton').value = "Forhåndsbestill";
        }

        document.querySelector('.loader').classList.add('done');

      },
      error: function (request, status, error) {
      }
    });

  }
}