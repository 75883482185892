﻿import axios from 'axios';
import throttle from './lib/throttle';


$(document).on('nopAnywhereSlidersFinishedLoading', function (e) {
  var allSliderAnchors = document.querySelectorAll('.anywhere-sliders-nivo-slider a');
  allSliderAnchors.forEach(function (e, index) {
    e.addEventListener('click', siteTransition);
  });
});

function loadImagesAsync() {
  var imagesToLoad = document.querySelectorAll('.async-image.not-loaded');
  imagesToLoad.forEach(function (imageToLoad, index) {
    if (isAnyPartOfElementInViewport(imageToLoad)) {
      var dataId = imageToLoad.getAttribute('load-id');
      var dataType = imageToLoad.getAttribute('load-type');
      imageToLoad.classList.remove('not-loaded');
      axios.get('/Misc/LoadImage/' + dataId + '/' + dataType)
        .then(function (response) {
          if (response.data === "") {
            imageToLoad.parentElement.remove();
          }
          else {
            imageToLoad.onload = function () {
              setSrcOnAsyncImage();
            };
            imageToLoad.src = response.data;
            imageToLoad.classList.add("img-loaded");
          }
          imageToLoad.parentElement.classList.remove('loading');
          imageToLoad.parentElement.classList.add('image-loaded');
        })
        .catch(function (error) {
          imageToLoad.parentElement.classList.remove('loading');
          imageToLoad.parentElement.classList.add('empty');
        })
        .then(function () {
        });
    }
  })
}

window.setSrcOnAsyncImage = function () {
  var imagesWithDataSrc = document.querySelectorAll('img[data-src]');

  var imagesWithSrc = [];
  imagesWithDataSrc.forEach(function (image, index) {
    var isInViewPort = isAnyPartOfElementInViewport(image);

    if (image.getAttribute('data-src') !== "" && isInViewPort) {
      imagesWithSrc.push(image);
    }
  })

  imagesWithSrc.forEach(function (img, index) {
    var imgSrc = img.getAttribute('data-src');
    img.src = imgSrc;
    img.removeAttribute('data-src');

    setTimeout(function () {
      img.classList.add("img-loaded");
      img.parentElement.parentElement.classList.add('image-loaded');
      //img.parentElement.parentElement.querySelectorAll('.img-loader').classList.add('remove');
    }, 200);

  });
}

function isAnyPartOfElementInViewport(el) {

  const rect = el.getBoundingClientRect();
  // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  return (vertInView && horInView);
}

setTimeout(function () {
  loadImagesAsync();
}, 200);

var throttledSrcAsyncImages = throttle(setSrcOnAsyncImage, 300);
$(window).scroll(throttledSrcAsyncImages);

var throttledloadImagesAsync = throttle(loadImagesAsync, 300);
$(window).scroll(throttledloadImagesAsync);

var throttledloadImagesAsyncTouchMove = throttle(loadImagesAsync, 300);
document.addEventListener("touchmove", throttledloadImagesAsyncTouchMove, false);

var throttledSrcAsyncImagesTouchMove = throttle(setSrcOnAsyncImage, 300);
document.addEventListener("touchmove", throttledSrcAsyncImagesTouchMove, false);

