var isDevice = false;

function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
  //console.log(getWidth());
  if (getWidth() < 1000) {
    isDevice = true;
    //console.log("jau");
  }

}

let mobile = window.matchMedia('(min-width: 0px) and (max-width: 1199px)');
console.log("HEIHEI")

window.toggleNav = function () {
  console.log("toggleNav");
    var mainBody = document.getElementsByTagName("html")[0];

    if (mainBody.classList.contains('menu-active')) {

        document.querySelectorAll('.top-menu .level0.has-children > a').forEach((f) => {
            f.classList.remove('active');
            f.classList.remove('expanded');
            f.classList.remove('fade-down');
        });

        document.querySelectorAll('.sublist').forEach((f) => {
            f.classList.remove('active');
        });

        document.querySelectorAll('.top-menu .slide-left').forEach(el => el.classList.remove('slide-left'));
        document.querySelectorAll('.top-menu .active').forEach(el => el.classList.remove('active'));

        mainBody.classList.remove("menu-active");
        mainBody.classList.add("menu-not-active");

    } else {
        mainBody.classList.add("menu-active");
        mainBody.classList.remove("menu-not-active");
    }

    document.querySelectorAll('.slide-left').forEach(el => el.classList.remove('slide-left'));
};

function customMenuClick(event) {
    let level = this.dataset.menuLevel;
    console.log("customMenuClick: " + this);

    if (mobile.matches) {
        // MOBILE MENU
       

        this.classList.toggle('active');
        this.parentElement.classList.toggle('active');

        document.querySelectorAll(`.top-menu .level${level}:not(.active)`).forEach(el => el.classList.toggle('slide-left'));
        document.querySelectorAll(`.top-menu .goback-level${level-1}`).forEach(el => el.classList.toggle('slide-left'));

        if(this.nextElementSibling) {
            this.nextElementSibling.classList.toggle('active');
        }

        if (this.classList.contains('level1-desktop')) {
          console.log('has-sub-nav.level1-desktop');  
          document.querySelector('.custom-loader').classList.remove('show');
          
          event.preventDefault();

          return false;
          
          

      }

    }
    else 
    {
        // DESKTOP MENU


        var mainBody = document.getElementsByTagName("html")[0];

        if (mainBody.classList.contains('menu-active')) { // MENY ALLEREDE AKTIV

            if (this.classList.contains('active')) { 
                document.querySelectorAll('.top-menu .active').forEach(el => el.classList.remove('active'));
                document.querySelectorAll('.top-menu .first-level').forEach(el => el.classList.remove('no-animation'));

                mainBody.classList.remove("menu-active");
                mainBody.classList.add("menu-not-active");

            } else {
                document.querySelectorAll('.top-menu .first-level').forEach(el => el.classList.add('no-animation'));
                document.querySelectorAll('.top-menu .active').forEach(el => el.classList.toggle('active'));
                
                //mainBody.classList.remove("menu-active");
                //mainBody.classList.add("menu-not-active");
    
    
                this.classList.toggle('active');
                this.parentElement.classList.toggle('active');
                
        
                if(this.nextElementSibling) {
                    this.nextElementSibling.classList.toggle('active');
                }

            }


    
        } else {
            mainBody.classList.add("menu-active");
            mainBody.classList.remove("menu-not-active");

            this.classList.toggle('active');
            this.parentElement.classList.toggle('active');
            
    
            if(this.nextElementSibling) {
                this.nextElementSibling.classList.toggle('active');
            }
        }

        if(level > 0) {
            document.querySelector('.custom-page-loader').classList.add('show');
            window.location.href = this.dataset.href;
        }
    }

    return false;
}

function customMenuBack() {
    let level = this.dataset.menuLevel;
    this.parentElement.parentElement.classList.toggle('active');
    document.querySelectorAll(`.top-menu .level${level}.slide-left`).forEach(el => el.classList.remove('slide-left'));
    document.querySelectorAll(`.top-menu .goback-level${level-1}.slide-left`).forEach(el => el.classList.remove('slide-left'));

    this.parentElement.classList.toggle('slide-left');
    this.parentElement.parentElement.parentElement.classList.toggle('active');
    this.parentElement.parentElement.parentElement.querySelector('.menu-item.toggle').classList.toggle('active');
}

document.querySelectorAll('.top-menu .toggle').forEach(el => el.addEventListener('click', customMenuClick, false));
document.querySelectorAll('.top-menu a.menu-goback').forEach(el => el.addEventListener('click', customMenuBack));
