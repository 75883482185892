/**
 * @fileoverview dragscroll - scroll area by dragging
 * @version 0.0.8
 * 
 * @license MIT, see http://github.com/asvd/dragscroll
 * @copyright 2015 asvd <heliosframework@gmail.com> 
 */


(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['exports'], factory);
  } else if (typeof exports !== 'undefined') {
    factory(exports);
  } else {
    factory((root.dragscroll = {}));
  }
}(this, function (exports) {
  var _window = window;
  var _document = document;
  var mousemove = 'mousemove';
  var mouseup = 'mouseup';
  var mousedown = 'mousedown';
  var wheel = 'wheel';
  var EventListener = 'EventListener';
  var addEventListener = 'add' + EventListener;
  var removeEventListener = 'remove' + EventListener;
  var newScrollX, newScrollY;
  var isScrolling;

  var ticking = false;
var lastScrollLeft = 0;

  var dragged = [];
  var reset = function (i, el) {
    //console.log("DRAGSCROLL RESET")
    for (i = 0; i < dragged.length;) {
      el = dragged[i++];
      el = el.container || el;
      el[removeEventListener](mousedown, el.md, 0);
      _window[removeEventListener](mouseup, el.mu, 0);
      _window[removeEventListener](mousemove, el.mm, 0);
    }

    // cloning into array since HTMLCollection is updated dynamically
    dragged = [].slice.call(_document.getElementsByClassName('dragscroll'));
    //console.log("DRAGSCROLL RESET: " + dragged.length);

    for (i = 0; i < dragged.length;) {
      (function (el, lastClientX, lastClientY, pushed, scroller, cont) {
        (cont = el.container || el)[addEventListener](
          mousedown,
          cont.md = function (e) {
            if (!el.hasAttribute('nochilddrag') ||
              _document.elementFromPoint(
                e.pageX, e.pageY
              ) == cont
            ) {
              pushed = 1;
              lastClientX = e.clientX;
              lastClientY = e.clientY;
            }
          }, 0
        );

        _window[addEventListener](
          mouseup, cont.mu = function () {
            setSrcOnAsyncImage();
            pushed = 0;
            el.classList.remove('active'); // HK Custom
            //console.log("scrolled nu?!!!!!");
          }, 0
        );

        _window[addEventListener]( // HK Custom
          wheel, cont.mu = function (e) {

            if (e.deltaX > 0) {
              window.clearTimeout( isScrolling );

              isScrolling = setTimeout(function() {
                //console.log( 'Scrolling has stopped.' );
                setSrcOnAsyncImage();
                pushed = 0;
                el.classList.remove('active'); // HK Custom

              }, 250);

            }

          }, 0
        );

        _window[addEventListener](
          mousemove,
          cont.mm = function (e) {

            if (pushed) {
              //console.log("scrolled!!!!!");
              el.classList.add('active'); // HK Custom
              (scroller = el.scroller || el).scrollLeft -=
                newScrollX = (- lastClientX + (lastClientX = e.clientX));
              scroller.scrollTop -=
                newScrollY = (- lastClientY + (lastClientY = e.clientY));
              if (el == _document.body) {
                (scroller = _document.documentElement).scrollLeft -= newScrollX;
                scroller.scrollTop -= newScrollY;
              }
            }
          }, 0
        );
      })(dragged[i++]);
    }
  }


  if (_document.readyState == 'complete') {
    reset();
  } else {
    _window[addEventListener]('load', reset, 0);
  }

  exports.reset = reset;
}));

