﻿import axios from 'axios';
import throttle from './lib/throttle';

function loadCategoriesAsync() {
  var categoriesToLoad = document.querySelectorAll('.async-product-wrapper.loading');

  categoriesToLoad.forEach(function (categoryToLoad, index) {
    var dataId = categoryToLoad.getAttribute('load-id');
    categoryToLoad.classList.remove('loading');
    axios.get('/Misc/LoadCategory/' + dataId)
      .then(function (response) {
        categoryToLoad.innerHTML = response.data;
        $.event.trigger({ type: "newProductsAddedToPageEvent" });
        window.setSrcOnAsyncImage();
      })
      .catch(function (error) {
        categoryToLoad.parentElement.classList.remove('loading');
        categoryToLoad.parentElement.classList.add('empty');
      })
      .then(function () {
      });

  })
}

window.setSrcOnAsyncImage = function () {
  var imagesWithDataSrc = document.querySelectorAll('img[data-src]');

  var imagesWithSrc = [];
  imagesWithDataSrc.forEach(function (image, index) {
    var isInViewPort = isAnyPartOfElementInViewport(image);

    if (image.getAttribute('data-src') !== "" && isInViewPort) {
      imagesWithSrc.push(image);
    }
  })

  imagesWithSrc.forEach(function (img, index) {
    var imgSrc = img.getAttribute('data-src');
    img.src = imgSrc;
    img.removeAttribute('data-src');

    setTimeout(function () {
      img.classList.add("img-loaded");
      img.parentElement.parentElement.classList.add('image-loaded');
    }, 200);

  });
}

function isAnyPartOfElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
  return (vertInView && horInView);
}

setTimeout(function () {
  loadCategoriesAsync();
}, 200);

var throttledloadCategoriesAsync = throttle(loadCategoriesAsync, 300);
$(window).scroll(throttledloadCategoriesAsync);